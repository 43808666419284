'use client';

import React from 'react';
import { onValue, ref } from 'firebase/database';
import { authFirebase, database } from '@/lib/firebase';
import { onAuthStateChanged, signInAnonymously, User } from 'firebase/auth';

interface FirebaseContextType {
  isOnline: (id: number) => boolean;
  loading: boolean;
}

const FirebaseContext = React.createContext<FirebaseContextType | undefined>(undefined);

export const FirebaseProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [data, setData] = React.useState(null); // Store database data
  const [user, setUser] = React.useState<User | null>(null);
  const [error, setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(authFirebase, (user) => {
      setLoading(true);
      if (user) {
        setUser(user);
      } else {
        signInAnonymously(authFirebase).catch((error) => {
          setError(`Authentication error: ${error.message}`);
        });
      }
    });

    return () => {
      unsubscribeAuth();
      setLoading(false);
    };
  }, []);

  React.useEffect(() => {
    if (!user) return;
    // Reference to a specific node in the database (e.g., "/items")
    const dbRef = ref(database, '/online');

    // Listen for changes in the database
    const unsubscribe = onValue(dbRef, (snapshot) => {
      if (snapshot.exists()) {
        const value = snapshot.val();
        setData(value); // Update state with new data
        setLoading(false);
      }
    });

    return () => {
      unsubscribe();
      setLoading(false);
    };
  }, [user]);

  const isOnline = (id: number) => Boolean(data?.[id]);

  return (
    <FirebaseContext.Provider
      value={{
        isOnline,
        loading,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

export const useFirebase = () => {
  const context = React.useContext(FirebaseContext);
  if (!context) {
    throw new Error('useFirebase must be used within a FirebaseProvider');
  }
  return context;
};
