import { useInfiniteQuery } from '@tanstack/react-query';
import { UserCommunityParamsType } from '@/types';
import { getUserJoined } from '@/services/communities.service';

const useGetUserJoinedCommunitiesQuery = ({
  PageSize = 12,
  PageNumber,
  ...rest
}: UserCommunityParamsType) => {
  const { data, ...other } = useInfiniteQuery({
    initialPageParam: 1,
    queryKey: ['GetUserJoinedCommunitiesQuery', { PageSize, ...rest }],
    queryFn: ({ pageParam }) =>
      getUserJoined({ PageSize, PageNumber: rest.Search?.trim() ? 1 : pageParam, ...rest }),
    getNextPageParam: (lastPage: any, pages) =>
      lastPage?.hasNextPage ? pages.length + 1 : undefined,
    enabled: rest.enabled || !rest.disabled,
  });

  const payload = data?.pages.reduce((acc, page) => [...acc, ...page.data], []);

  return { ...other, data: payload, total: data?.pages[0]?.totalCount ?? '0' };
};

export default useGetUserJoinedCommunitiesQuery;
