'use client';

import React from 'react';
import { useRouter, useSearchParams, usePathname } from 'next/navigation';
import useCommunityStore from '@/hooks/store/use-community-store';
import ModalNew from '@/components/atom/modal-new';
import { Button } from '@/components/atom/button';
import { Input } from '@/components/atom/input';
import { CommunityTypeOptions } from '@/constants';
import CommunityImageInput from './CommunityImageInput';
import useGetCommunityCategories from '@/hooks/react-query/communities/queries/useGetCommunityCategories';
import useGetCommunityInterests from '@/hooks/react-query/communities/queries/useGetCommunityInterests';
import RadioButton from '@/components/atom/radio-button';
import { useToast } from '@/hooks/use-toast';
import useTipTapEditor from '@/hooks/use-tiptap-editor';
import { EditorContent } from '@tiptap/react';
import useCreateCommunityMutation from '@/hooks/react-query/communities/mutations/useCreateCommunityMutation';
import { CreateCommunityPayloadType } from '@/types';
import { Loader2Icon } from 'lucide-react';
import { Controller, useForm } from 'react-hook-form';
import { CommunityFormModel } from '@/models/community.model';
import { communityValidationSchema } from '@/lib/schemas';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { SelectInput } from '@/components/atom/SelectInput';

const CreateCommunityModal = () => {
  const router = useRouter();
  const pathname = usePathname();
  const params = useSearchParams();
  const { communitySuccess, setCommunitySuccess } = useCommunityStore();
  const opened = params.get('type') === 'community' && !communitySuccess;
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const [img, setImg] = React.useState('');
  const [file, setFile] = React.useState<File | undefined | null>();
  const containerRef = React.useRef(null);
  const {
    data: categoryOptions,
    isLoading: loadingCategories,
    hasNextPage: categoryHasNextPage,
    fetchNextPage: fetchNextCategoryPage,
    isFetching: fetchingCategories,
  } = useGetCommunityCategories({ Label: '', Search: '' });
  const {
    data: interestOptions,
    isLoading: loadingInterests,
    hasNextPage: interestsHasNextPage,
    fetchNextPage: fetchNextInterestsPage,
    isFetching: fetchingInterests,
  } = useGetCommunityInterests({ Search: '' });
  const { control, handleSubmit, formState, watch } = useForm<CommunityFormModel>({
    resolver: zodResolver(communityValidationSchema),
    mode: 'onChange',
  });

  const { editor, value } = useTipTapEditor({
    placeholder: 'Write a short description of your community (Maximum number of words: 100)',
    className: '!min-h-[152px]',
  });

  const createCommunityMutation = useCreateCommunityMutation();

  const onSubmit = () => {
    const formValues: CommunityFormModel = watch();
    if (file && img) {
      const base64 = img.split(',')[1];
      const payload: CreateCommunityPayloadType = {
        // categoryId: Number(formValues.category),
        interestId: Number(formValues.interest),
        description: value,
        image: {
          base64,
          contentType: file?.type ?? '',
          name: file?.name ?? '',
        },
        name: formValues.name,
        handle: formValues.name,
        communityTypeId: Number(formValues.type),
      };

      createCommunityMutation.mutate(payload, {
        onSuccess: (res) => {
          router.replace(pathname);

          setCommunitySuccess({ base64: img, name: formValues.name, ...res });
          queryClient.invalidateQueries();
        },

        onError: (err: unknown) => {
          const error = err as unknown as string;
          toast({
            variant: 'destructive',
            description: error ?? 'Unable to create community. Please try again later.',
          });
        },
      });
    } else {
      toast({ variant: 'destructive', description: 'Please select an image or take a picture' });
    }
  };
  return (
    <ModalNew
      isOpen={opened}
      onClose={() => router.back()}
      asDrawerOnMobile={true}
      title="Create Community"
      description="Create a space where you can connect with people of like mind and shared interest"
      // fullScreenScroll={fullScreenScroll}
      footer={
        <div className="w-full mt-4">
          <Button className="w-full" type="button" onClick={() => handleSubmit(onSubmit)()}>
            {createCommunityMutation.isPending ? (
              <>
                <Loader2Icon size={16} className="animate-spin mr-2" /> Processing...
              </>
            ) : (
              'Create'
            )}
          </Button>
        </div>
      }
    >
      <form className="w-full" ref={containerRef}>
        <div className="mb-4">
          <CommunityImageInput
            onImageCaptured={(v, f) => {
              setImg(v);
              setFile(f);
            }}
            accept="image/*"
            disabled={createCommunityMutation.isPending}
          />
        </div>
        <div className="w-full mb-4">
          <label className="text-sm font-medium" htmlFor="name">
            Name of community
          </label>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                errorMessage={formState.errors.name?.message}
                placeholder="Enter the community name"
                disabled={createCommunityMutation.isPending}
              />
            )}
          />
        </div>

        <div className="w-full mb-4">
          <label className="text-sm font-medium" htmlFor="interest">
            Category
          </label>
          <Controller
            name="interest"
            control={control}
            render={({ field }) => (
              <SelectInput
                loading={loadingInterests}
                options={
                  interestOptions?.map((cat: any) => ({
                    searchKey: cat.name,
                    label: cat.name,
                    value: cat.id.toString(),
                  })) ?? []
                }
                onBottomReached={() =>
                  interestsHasNextPage && !fetchingInterests && fetchNextInterestsPage()
                }
                errorMessage={formState.errors.interest?.message}
                placeholder={
                  loadingCategories ? 'Loading...' : 'Select a category for your community'
                }
                value={field.value?.toString()}
                onChange={field.onChange}
              />
            )}
          />
          {formState.errors?.interest?.message && (
            <p className="text-sm text-opal-brand transition duration-500 ease-in-out">
              {formState.errors?.interest?.message}
            </p>
          )}
        </div>

        {/* <div className="w-full mb-4">
          <label className="text-sm font-medium" htmlFor="interest">
            Interest
          </label>
          <Controller
            name="interest"
            control={control}
            render={({ field }) => (
              <SelectInput
                loading={loadingInterests}
                disabled={createCommunityMutation.isPending}
                options={
                  interestOptions?.map((interest: any) => ({
                    searchKey: interest.name,
                    label: interest.name,
                    value: interest.id.toString(),
                  })) || []
                }
                onBottomReached={() =>
                  interestsHasNextPage && !fetchingInterests && fetchNextInterestsPage()
                }
                errorMessage={formState.errors.interest?.message}
                placeholder={
                  loadingInterests ? 'Loading...' : 'Select an interest for your community'
                }
                // {...field}
                onChange={field.onChange}
                value={field.value?.toString()}
              />
            )}
          />
          {formState.errors?.interest?.message && (
            <p className="text-sm text-opal-brand transition duration-500 ease-in-out">
              {formState.errors?.interest?.message}
            </p>
          )}
        </div> */}

        <div className="w-full mb-4">
          <label className="text-sm font-medium" htmlFor="description">
            Description
          </label>
          <EditorContent editor={editor} disabled={createCommunityMutation.isPending} />
          <p className="text-end text-xs text-gray-brand">
            {150 - value?.trim()?.length} of 150 words left
          </p>
        </div>
        <div className="w-full mb-4">
          <label htmlFor="type">Community type</label>
          <div className="grid grid-cols-2 gap-4">
            <Controller
              name="type"
              control={control}
              render={({ field, fieldState: { error, isDirty } }) => (
                <>
                  {CommunityTypeOptions.map((v) => (
                    <RadioButton
                      key={v.value}
                      id={v.value.toString()}
                      value={v.value}
                      label={v.label}
                      name={field.name}
                      onChange={field.onChange}
                      error={isDirty ? error?.message : ''}
                    />
                  ))}
                </>
              )}
            />
          </div>
        </div>
      </form>
    </ModalNew>
  );
};

export default CreateCommunityModal;
