import { getCommunityCategories } from '@/services/communities.service';
import { PagedParams } from '@/types';
import { useInfiniteQuery } from '@tanstack/react-query';

const useGetCommunityCategories = ({
  PageSize = 10,
  PageNumber,
  enabled = true,
  ...rest
}: PagedParams & { Label: string }) => {
  const { data, ...other } = useInfiniteQuery({
    initialPageParam: 1,
    queryKey: ['GetCommunityCategories', { PageSize, ...rest }],
    queryFn: ({ pageParam }) =>
      getCommunityCategories({
        PageSize,
        PageNumber: rest.Search?.trim() ? 1 : pageParam,
        ...rest,
      }),
    getNextPageParam: (lastPage: any, pages) =>
      lastPage?.hasNextPage ? pages.length + 1 : undefined,
    enabled,
  });

  const payload = data?.pages.reduce((acc, page) => {
    return [...acc, ...page.data];
  }, []);

  return { ...other, data: payload, total: data?.pages[0]?.totalCount ?? '0' };
};

export default useGetCommunityCategories;
