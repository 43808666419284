'use client';

import React, { forwardRef } from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/atom/dialog';
import { Input, type InputProps } from '../atom/input';
import { Button } from '../atom/button';
import { GOOGLE_MAPS_API_KEY } from '@/configs/global-variables';
import { GoogleMap, Marker, useJsApiLoader, Autocomplete } from '@react-google-maps/api';
import { Loader2 } from 'lucide-react';
import { Library } from '@googlemaps/js-api-loader';

type LatLngType = {
  lng: number;
  lat: number;
};

const LAGOS_CENTER = { lat: 6.5244, lng: 3.3792 };
const LAGOS_BOUNDS = {
  north: 6.7027,
  south: 6.3939,
  west: 3.0982,
  east: 3.5407,
};

const libraries: Library[] = ['core', 'places', 'maps', 'marker', 'geocoding'];

interface AddressInputProps extends InputProps {
  label?: string;
}

const AddressInput = forwardRef<HTMLInputElement, AddressInputProps>(
  ({ name, onChange, value, label = 'Address', ...props }, ref) => {
    const [center, setCenter] = React.useState(LAGOS_CENTER);
    const [isLoading, setIsLoading] = React.useState(true);
    const [isAddressLoading, setIsAddressLoading] = React.useState(false);
    const [address, setAddress] = React.useState('');
    const [selectedLocation, setSelectedLocation] =
      React.useState<google.maps.LatLngLiteral | null>(null);
    const [isMapOpen, setIsMapOpen] = React.useState(false);
    const mapRef = React.useRef<google.maps.Map | null>(null);
    const autocompleteRef = React.useRef<google.maps.places.Autocomplete | null>(null);
    const geocoderRef = React.useRef<google.maps.Geocoder | null>(null);

    const { isLoaded, loadError } = useJsApiLoader({
      id: 'google-map-script',
      googleMapsApiKey: GOOGLE_MAPS_API_KEY,
      libraries,
    });

    const onUnmount = React.useCallback(function callback() {
      mapRef.current = null;
    }, []);

    const onMapLoad = React.useCallback((map: google.maps.Map) => {
      mapRef.current = map;
      const geocoder = new google.maps.Geocoder();
      geocoderRef.current = geocoder;
    }, []);
    const onAutocompleteLoad = React.useCallback(
      (autocomplete: google.maps.places.Autocomplete) => {
        autocompleteRef.current = autocomplete;
      },
      []
    );

    const onPlaceChanged = () => {
      if (autocompleteRef.current) {
        const place = autocompleteRef.current.getPlace();
        if (place.formatted_address) {
          setAddress(place.formatted_address);
          if (place.geometry) {
            const newCenter = {
              lat: place.geometry.location?.lat() as number,
              lng: place.geometry.location?.lng() as number,
            };
            setSelectedLocation(newCenter);
          }
        } else {
          setAddress('');
        }
      }
    };

    const handleSelectFromMap = () => {
      if (mapRef.current) {
        const center = mapRef.current.getCenter();
        if (center) {
          const newCenter = { lat: center.lat(), lng: center.lng() };
          setSelectedLocation(newCenter);
        }
      }
    };

    const handleMapClick = (event: google.maps.MapMouseEvent) => {
      if (event.latLng) {
        const newCenter = { lat: event.latLng.lat(), lng: event.latLng.lng() };
        setSelectedLocation(newCenter);
      }
    };

    const handleMapSelection = (selectedAddress: LatLngType | null) => {
      console.log(selectedAddress);

      if (selectedAddress?.lat && selectedAddress?.lng) {
        getAddressFromLatLng(selectedAddress?.lat, selectedAddress.lng);
        setSelectedLocation(selectedAddress);
        setIsMapOpen(false);
      }
      // onChange?.({target: {value: selectedAddress, name}});
    };

    console.log({ address });
    React.useEffect(() => {
      setTimeout(() => (document.body.style.pointerEvents = ''), 0);
    }, []);
    React.useEffect(() => {
      if (selectedLocation) {
        setCenter(selectedLocation);
      }
    }, [selectedLocation]);

    const getAddressFromLatLng = (lat: number, lng: number) => {
      console.log({ lng, lat });
      if (geocoderRef.current) {
        setIsAddressLoading(true);
        geocoderRef.current.geocode({ location: { lat, lng } }, (results, status) => {
          console.log({ status, results });
          if (status === 'OK' && results && results[0]) {
            setAddress(results[0].formatted_address);
          } else {
            setAddress('');
          }
          setIsAddressLoading(false);
        });
      }
    };

    const isWithinLagosBounds = (lat: number, lng: number) => {
      return (
        lat >= LAGOS_BOUNDS.south &&
        lat <= LAGOS_BOUNDS.north &&
        lng >= LAGOS_BOUNDS.west &&
        lng <= LAGOS_BOUNDS.east
      );
    };

    React.useEffect(() => {
      onChange?.({ target: { value: address, name } } as React.ChangeEvent<HTMLInputElement>);
    }, [address]);

    React.useEffect(() => {
      if (isMapOpen) {
        handleSelectFromMap();
      }
    }, [isMapOpen]);

    React.useEffect(() => {
      if (isLoaded && !loadError) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            if (isWithinLagosBounds(latitude, longitude)) {
              setSelectedLocation({ lat: latitude, lng: longitude });
              if (mapRef.current) {
                mapRef.current.panTo({ lat: latitude, lng: longitude });
              }
            } else {
              setSelectedLocation(LAGOS_CENTER);
            }
            setIsLoading(false);
          },
          () => {
            setSelectedLocation(LAGOS_CENTER);
            setIsLoading(false);
          },
          { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
        );
      }
    }, [isLoaded, loadError]);

    return (
      <div className="w-full">
        <label className="text-sm font-medium flex items-center justify-between" htmlFor={name}>
          {label}{' '}
          <Dialog open={isMapOpen} onOpenChange={setIsMapOpen}>
            <DialogTrigger asChild>
              <button className="text-xs text-orange-brand font-medium" type="button">
                Select on map
              </button>
            </DialogTrigger>
            <DialogContent
              className="sm:max-w-[425px] space-y-6"
              aria-describedby="google map content"
            >
              <DialogHeader>
                <DialogTitle>Select Address from Map</DialogTitle>
                <DialogDescription>
                  <Autocomplete
                    onLoad={onAutocompleteLoad}
                    bounds={LAGOS_BOUNDS}
                    restrictions={{ country: 'ng' }}
                    onPlaceChanged={onPlaceChanged}
                  >
                    <input
                      className="flex h-9 w-full max-w-[250px] duration-500 ease-in-out rounded-sm border border-gray-brand2 bg-white px-3 py-1 text-sm transition text-black-brand file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-gray-brand9 focus-visible:outline-none focus-visible:ring-0 focus-visible:border-purple-brand disabled disabled:bg-gray-brand4"
                      onChange={(e) => console.log(e.target.value)}
                    />
                  </Autocomplete>
                </DialogDescription>
              </DialogHeader>
              <div className="h-[300px] bg-gray-100 flex items-center justify-center">
                {(isLoading || isAddressLoading) && (
                  <div className="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-50 z-10">
                    <Loader2 className="h-8 w-8 animate-spin text-primary" />
                  </div>
                )}
                {loadError ? (
                  <p className="text-red-500">Error loading map</p>
                ) : isLoaded ? (
                  <GoogleMap
                    onUnmount={onUnmount}
                    mapContainerClassName="w-full h-full"
                    center={center}
                    zoom={17}
                    onLoad={onMapLoad}
                    onClick={handleMapClick}
                    options={{
                      restriction: {
                        latLngBounds: LAGOS_BOUNDS,
                        strictBounds: true,
                      },
                    }}
                  >
                    {selectedLocation && <Marker position={selectedLocation} />}
                  </GoogleMap>
                ) : (
                  <p className="text-gray-500">Loading...</p>
                )}
              </div>
              {/* <Map /> */}
              <div className="w-full flex items-center justify-end gap-4">
                <Button variant="outline" onClick={() => setIsMapOpen(false)}>
                  Cancel
                </Button>
                <Button
                  disabled={!selectedLocation || isAddressLoading}
                  onClick={() => handleMapSelection(selectedLocation)}
                >
                  Select This Location
                </Button>
              </div>
            </DialogContent>
          </Dialog>{' '}
        </label>
        <Input {...props} id={name} ref={ref} value={value} onChange={onChange} />
      </div>
    );
  }
);

AddressInput.displayName = 'AddressInput';

export default AddressInput;
