import { ImageGalleryAdd, LinearLayer, ThrashIcon } from '@/components/icons';
import React, { useState } from 'react';
import { PlayIcon } from '@/components/icons';
import { useToast } from '@/hooks/use-toast';
import { MAX_NUMBER_POST_IMAGES } from '@/configs/global-variables';
import { MediaType } from '@/types';
import Img from '@/components/atom/img';

export interface FileInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onFileSelected: (v: MediaType[], selectedFiles: File[]) => void;
  imageRef: React.ForwardedRef<HTMLInputElement>;
  videoRef: React.ForwardedRef<HTMLInputElement>;
}

const MultipleMediaUploader = React.forwardRef<HTMLInputElement, FileInputProps>(
  ({ onFileSelected, className, onChange, imageRef, videoRef, ...props }) => {
    const [mediaItems, setMediaItems] = useState<MediaType[]>([]);
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const { toast } = useToast();

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;

      if (files) {
        const fileArray = Array.from(files);

        const hasInvalidFile = fileArray.some(
          (file) => !file.type.includes('video') && !file.type.includes('image')
        );

        if (hasInvalidFile) {
          toast({
            variant: 'destructive',
            title: 'Invalid File format',
            description: 'You can only select videos and images.',
          });
          return; // Exit the entire function
        }

        if (mediaItems.length + fileArray.length > MAX_NUMBER_POST_IMAGES) {
          toast({
            variant: 'destructive',
            description: 'You can not select more than 5 media files.',
          });
          return;
        }

        setSelectedFiles(fileArray);

        const newImages = fileArray.map((file) => convertToBase64(file));

        Promise.all(newImages).then((base64Images) => {
          setMediaItems((prevImages) => [...prevImages, ...base64Images].slice(0, 5));
        });
      }
    };

    const convertToBase64 = (file: File): Promise<MediaType> => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          if (reader.result) {
            resolve({
              name: file.name,
              contentType: file.type,
              base64: reader.result.toString(),
              file: file,
            });
          } else {
            reject(new Error('Failed to convert file to base64'));
          }
        };
        reader.onerror = () => reject(new Error('Failed to read file'));
        reader.readAsDataURL(file);
      });
    };

    const handleRemoveImage = (index: number) => {
      setMediaItems((prevImages) => {
        const newImages = prevImages.filter((_, i) => i !== index);
        // Update the file input value
        // if (ref.current) {
        //   const dt = new DataTransfer();
        //   newImages.forEach((img) => dt.items.add(img.file));
        //   ref.current.files = dt.files;
        // }
        return newImages;
      });
    };

    React.useEffect(() => {
      const imgs = mediaItems.map((v) => ({ ...v, base64: v.base64.split(',')[1] }));
      onFileSelected(imgs, selectedFiles);
    }, [mediaItems, selectedFiles]);

    return (
      <div className="h-full flex gap-4 overflow-x-auto">
        {/* <label
          className={cn(
            'flex items-center justify-center w-24 h-24 min-w-24 border border-dashed border-gray-brand2 rounded-lg cursor-pointer',
            mediaItems.length === 0 && 'hidden h-0'
          )}
        >
          <input
            ref={imageRef}
            type="file"
            className="hidden"
            onChange={(e) => {
              handleFileChange(e);
              onChange?.(e);
            }}
            multiple
            accept="image/*"
            {...props}
          />
          <input
            ref={videoRef}
            type="file"
            className="hidden"
            onChange={(e) => {
              handleFileChange(e);
              onChange?.(e);
            }}
            multiple
            accept="video/*"
            {...props}
          />
          <ImageGalleryAdd />
        </label> */}

        <input
          ref={imageRef}
          type="file"
          className="hidden"
          onChange={(e) => {
            handleFileChange(e);
            onChange?.(e);
          }}
          multiple
          accept="image/*"
          {...props}
        />
        <input
          ref={videoRef}
          type="file"
          className="hidden"
          onChange={(e) => {
            handleFileChange(e);
            onChange?.(e);
          }}
          multiple
          accept="video/*"
          {...props}
        />

        {mediaItems.map((mediaFile, index) => (
          <>
            {mediaFile.contentType.includes('image') && (
              <div key={index + mediaFile.name} className="relative min-w-fit">
                <Img
                  src={mediaFile.base64}
                  alt={mediaFile.name}
                  className="w-auto h-24 rounded-lg"
                />
                <button
                  onClick={() => console.log('drag and drop')}
                  className="absolute top-0 right-0 w-6 h-6 bg-gray-brand12/60 text-white p-1 rounded-tr-lg"
                >
                  <LinearLayer />
                </button>
                <button
                  onClick={() => handleRemoveImage(index)}
                  className="absolute bottom-0 right-0 w-6 h-6 bg-gray-brand12/60 text-white p-1 rounded-br-lg"
                >
                  <ThrashIcon />
                </button>
              </div>
            )}
            {mediaFile.contentType.includes('video') && (
              <div
                key={index + mediaFile.name}
                className="relative h-24 w-36 bg-slate-300 rounded-lg min-w-36"
              >
                <div className="w-full h-full flex flex-col items-center justify-center gap-0.5">
                  <PlayIcon />
                  <p className="text-xs text-white ">{mediaFile.name.slice(0, 8) + '...'}</p>
                </div>
                <button
                  onClick={() => console.log('drag and drop')}
                  className="absolute top-0 right-0 w-6 h-6 bg-gray-brand12/60 text-white p-1 rounded-tr-lg"
                >
                  <LinearLayer />
                </button>
                <button
                  onClick={() => handleRemoveImage(index)}
                  className="absolute bottom-0 right-0 w-6 h-6 bg-gray-brand12/60 text-white p-1 rounded-br-lg"
                >
                  <ThrashIcon />
                </button>
              </div>
            )}
            {!mediaFile.contentType.includes('image') &&
              !mediaFile.contentType.includes('video') && (
                <div key={index + mediaFile.name} className="h-24 w-24 bg-yellow-200">
                  Unsupported file format
                </div>
              )}
          </>
        ))}
      </div>
    );
  }
);

export default MultipleMediaUploader;
