'use client';

import React from 'react';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@/components/atom/button';
import StepTwoForm from './StepTwoForm';
import { formSchema, ShopItemFormType } from './schemas';
import ShopItemFormPreview from './ShopItemFormPreview';
import ShopInfoForm from './ShopInfoForm';
import useCreateEventMutation from '@/hooks/react-query/shop/mutations/useCreateEventMutation';
import { CreateEventPayloadType } from '@/types';
import { ChannelEnum } from '@/utils/enums';
import { calculateTimeRange, setTimeOnDateAndReturnISO } from '@/lib/format-dates';
import { Loader2 } from 'lucide-react';
import { useToast } from '@/hooks/use-toast';
import { useQueryClient } from '@tanstack/react-query';
import useShopStore from '@/hooks/store/use-shop-store';
import { yupResolver } from '@hookform/resolvers/yup';

export default function CreateShopItemForm({
  step,
  setStep,
}: {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}) {
  const { setShopSuccess } = useShopStore();
  const queryClient = useQueryClient();
  const { toast } = useToast();
  const createEventMutation = useCreateEventMutation();
  const form = useForm<ShopItemFormType>({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      multiple: false,
    },
  });

  const onSubmit: SubmitHandler<ShopItemFormType> = (data) => {
    const range = calculateTimeRange(data?.eventTime?.replace(' ', ''), data?.duration);
    const endTime = range.split('-')[1].trim();
    const numberPrice = parseFloat(data?.price?.replaceAll(/,/g, '') || '0');
    const cost = typeof numberPrice === 'number' ? numberPrice : 0;

    const payload: CreateEventPayloadType = {
      tags: [],
      allowComments: true,
      allowLikes: true,
      allowShare: true,
      channelId: ChannelEnum.Web,
      eventName: data.eventName,
      description: data.description ?? '',
      images: [
        {
          base64: data.base64?.split(',')[1] ?? '',
          contentType: data.imageFile?.type ?? '',
          name: data.imageFile?.name,
        },
      ],
      physicalLocation: data.venueAddress ?? '',
      start: setTimeOnDateAndReturnISO(data.eventDate, data.eventTime?.replace(' ', '')),
      end: setTimeOnDateAndReturnISO(
        data.multiple && data.endDate ? data.endDate : data.eventDate,
        endTime?.replace(' ', '')
      ),
      eventType: Number(data.eventType),
      ticketType: Number(data.ticketType),
      link: '',
      communityId: Number(data.communityId),
      cost,
      numberAvailable: Number(data.numbersAvailable),
      isHidden: false,
      multipleDays: Boolean(data?.multiple),
    };
    createEventMutation.mutate(payload, {
      onSuccess: (res) => {
        queryClient.invalidateQueries({ queryKey: ['GetUserExploreShop'] });
        queryClient.invalidateQueries({ queryKey: ['GetAllShopItems'] });
        setShopSuccess({
          base64: data.base64 ?? '',
          name: data.eventName,
          slug: res,
          ...res.data,
        });
      },
      onError: (err: any) => {
        toast({ variant: 'destructive', description: err?.response?.data?.messages[0] });
      },
    });
  };

  const onNextClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    let fieldsToValidate: (keyof ShopItemFormType)[] = [];
    switch (step) {
      case 1:
        fieldsToValidate = ['communityId', 'description', 'eventName', 'eventType'];
        break;
      case 2:
        fieldsToValidate = [
          'ticketType',
          'eventTime',
          'numbersAvailable',
          'price',
          'duration',
          'venueAddress',
          'eventDate',
        ];
        break;
    }
    const isStepValid = await form.trigger(fieldsToValidate);
    if (isStepValid) {
      setStep((prev) => prev + 1);
    }
  };

  const handleEdit = (section: string) => {
    if (section === 'info') {
      setStep(1);
    } else if (section === 'details') {
      setStep(2);
    } else {
      return;
    }
  };

  return (
    <FormProvider {...form}>
      <div className="w-full mt-6">
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex items-center justify-between flex-col gap-6"
        >
          {step === 1 && <ShopInfoForm />}

          {step === 2 && <StepTwoForm />}

          {step === 3 && <ShopItemFormPreview onEdit={handleEdit} />}

          <div className="flex flex-col md:flex-row justify-between gap-4 items-center w-full">
            {step > 1 ? (
              <Button
                type="button"
                className="w-full"
                onClick={() => setStep((prev) => prev - 1)}
                variant="outline"
              >
                Go Back
              </Button>
            ) : null}
            {step < 3 ? (
              <Button className="w-full" onClick={onNextClick} type="button">
                Next
              </Button>
            ) : (
              <Button type="submit" className="w-full" disabled={createEventMutation.isPending}>
                {createEventMutation.isPending ? (
                  <>
                    <Loader2 className="animate-spin mr-2" /> Processing...
                  </>
                ) : (
                  'Create Event'
                )}
              </Button>
            )}
          </div>
        </form>
      </div>
    </FormProvider>
  );
}
