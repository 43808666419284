import * as yup from 'yup';
import { EventShopTypeEnum, TicketType } from '@/utils/enums';

const step1Schema = yup.object({
  communityId: yup
    .string()
    .required('Please select a community')
    .min(1, 'Please select a community'),
  eventName: yup.string().required('Event name is required').min(1, 'Event name is required'),
  eventType: yup.string().required('Please select event type').min(1, 'Please select event type'),
  description: yup.string().nullable(),
  base64: yup.string().nullable(),
  imageFile: yup
    .mixed<any>()
    .nullable()
    .test('file-check', 'Invalid file type', (value) => value === null || value instanceof File),
});

const step2Schema = yup.object({
  ticketType: yup.string().required('Please select ticket type'),
  eventDate: yup.date().required('Please select a date').typeError("That's not a date!"),
  endDate: yup.date().nullable().typeError("That's not a date!"),
  multiple: yup.boolean().required(),
  eventTime: yup.string().required(),
  duration: yup.string().required(),
  price: yup.string().nullable(),
  numbersAvailable: yup.string().nullable(),
  venueAddress: yup.string().nullable(),
});

export const formSchema = step1Schema
  .concat(step2Schema)
  .test('custom-validations', 'Custom validation error', function (data) {
    const errors: yup.ValidationError[] = [];
    const { eventType, venueAddress, ticketType, price, numbersAvailable, multiple, endDate } =
      data;

    // Conditionally require venueAddress if eventType is 'Physical'
    if (eventType === `${EventShopTypeEnum.Physical}` && !venueAddress) {
      errors.push(
        new yup.ValidationError(
          'Venue address is required for physical events',
          null,
          'venueAddress'
        )
      );
    }

    // Conditionally require price and numbersAvailable if ticketType is 'Paid'
    if (ticketType === `${TicketType.Paid}`) {
      if (!price || price.trim() === '') {
        errors.push(new yup.ValidationError('Price is required for paid events', null, 'price'));
      }
      if (!numbersAvailable || numbersAvailable.trim() === '') {
        errors.push(
          new yup.ValidationError(
            'Number of tickets available is required for paid events',
            null,
            'numbersAvailable'
          )
        );
      }
    }

    // Conditionally require price and numbersAvailable if ticketType is 'Paid'
    if (multiple) {
      if (!endDate) {
        errors.push(new yup.ValidationError('Price is required for paid events', null, 'endDate'));
      }
    }

    // Add errors to validation context
    if (errors.length > 0) {
      throw new yup.ValidationError(errors);
    }
    return true;
  });

export type ShopItemFormType = yup.InferType<typeof formSchema>;
